<template>
  <div>
    <template v-if="banners && banners.length > 0">
      <SliderFull :banners="banners" />
    </template>

    <main class="wrapper container-fluid" role="main">
      <template v-if="!showLoader">
        <!-- La doble condición me permite mostrar el header al inicio, cuando aún no se si voy a traer datos o no.
					Si finalmente no hay datos, se oculta.
					Es preferible que se oculte si no hay datos, a que se muestre si hay, ya que casi siempre va a haber,
					y no está bueno que el layout dé un salto.
			 	-->
        <section
          class="row featured-content"
          v-if="!tendencia.consultada || tendencia.seleccionada != ''"
        >
          <template v-if="tendencia.seleccionada != ''">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" v-if="tendencia.actual.length > 0">
                <a
                  v-on:click.prevent="toggleTendencia('actual')"
                  class="nav-link"
                  :class="{ active: tendencia.seleccionada == 'actual' }"
                  data-toggle="tab"
                  href="#tv"
                  role="tab"
                >
                  <i class="zmdi zmdi-tv-alt-play"></i>
                  TV en vivo
                </a>
              </li>
              <li class="nav-item" v-if="tendencia.ultimas24.length > 0">
                <a
                  v-on:click.prevent="toggleTendencia('ultimas24')"
                  class="nav-link"
                  :class="{ active: tendencia.seleccionada == 'ultimas24' }"
                  data-toggle="tab"
                  href="#latest"
                  role="tab"
                >
                  <i class="zmdi zmdi-time-restore"></i>
                  Ver últimas 24 horas
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div
                class="tab-pane tab-pane--tv fade show active"
                role="tabpanel"
              >
                <Slider
                  :data="tendencia.actual"
                  key="tendencia_actual"
                  v-if="
                    tendencia.seleccionada == 'actual' &&
                    tendencia.actual.length > 0
                  "
                >
                  <template slot-scope="itemslider">
                    <CardTV
                      v-for="(item, index) of itemslider.data"
                      :key="item.idAsset"
                      :data="item"
                      componentOrigin="EPG"
                      :sliderId="'tendencia_actual'"
                      :sliderTitle="'Ahora en vivo'"
                      :cardId="index"
                    />
                  </template>
                </Slider>

                <Slider
                  :data="tendencia.ultimas24"
                  key="tendencia_ultimas24"
                  v-if="
                    tendencia.seleccionada == 'ultimas24' &&
                    tendencia.ultimas24.length > 0
                  "
                >
                  <template slot-scope="itemslider">
                    <CardTV
                      v-for="(item, index) of itemslider.data"
                      :key="item.idAsset"
                      :data="item"
                      componentOrigin="CUT"
                      :sliderId="'tendencia_ultimas24'"
                      :sliderTitle="'Últimas 24 hs'"
                      :cardId="index"
                    />
                  </template>
                </Slider>
              </div>
            </div>
          </template>
        </section>

        <template v-for="(item, index) of rails">
          <Slider :key="index" :data="item.data" :title="item.title">
            <template slot-scope="itemslider">
              <CardVOD
                v-for="item of itemslider.data"
                :key="item.idAsset"
                :data="item"
                componentOrigin="VOD"
                :sliderId="'slider-' + index"
                :sliderTitle="item.title"
                :cardId="'card-' + item.idAsset"
              />
            </template>
          </Slider>
        </template>

        <Slider
          v-show="continueWatchingRail.length > 0"
          :key="'continuar_viendo'"
          :title="'Continuar viendo'"
          :icon="'zmdi-movie-alt'"
          data-testid="rails"
          :data="continueWatchingRail"
        >
          <template slot-scope="itemslider">
            <CardVOD
              v-for="item of itemslider.data"
              :key="item.idAsset"
              :data="item"
              :sliderId="'slider-continuar_viendo'"
              :sliderTitle="'Continuar viendo'"
              :cardId="'card-' + item.idAsset"
            />
          </template>
        </Slider>

        <Slider
          v-show="watchAgainRail.length > 2"
          :key="'volver_aver'"
          :title="'Volver a ver'"
          :icon="'zmdi-movie-alt'"
          data-testid="rails"
          :data="watchAgainRail"
        >
          <template slot-scope="itemslider">
            <CardVOD
              v-for="item of itemslider.data"
              :key="item.idAsset"
              :data="item"
              :sliderId="'slider-volver_aver'"
              :sliderTitle="'Volver a ver'"
              :cardId="'card-' + item.idAsset"
            />
          </template>
        </Slider>
      </template>
    </main>

    <Loader v-if="showLoader" />
  </div>
</template>

<script>
import { getStatus } from "@/observables/epg";

const CANTIDAD_PAGINADO = 15;
const rango = {
  desde: 0,
  hasta: CANTIDAD_PAGINADO,
};

export default {
  name: "Home",

  components: {
    CardTV: () => import("@/components/Card-TV.vue"),
    CardVOD: () => import("@/components/Card-VOD.vue"),
    Loader: () => import("@/components/Loader.vue"),
    Slider: () => import("@/components/Slider.vue"),
    SliderFull: () => import("@/components/SliderFull.vue"),
  },

  data() {
    return {
      banners: [],
      rails: [],
      orderRails: [],
      tendencia: {
        actual: [],
        ultimas24: [],
        seleccionada: "",
        consultada: false, //Indica si ya se consultaron las tendencias
      },
      continueWatchingRail: [],
      watchAgainRail: [],
      showLoader: true,
      timerTendencia: null,
      tempRails: [],
    };
  },

  created() {
    // Agrego las clases solamente para la home
    document.body.classList.add("home");
  },

  mounted() {
    let self = this;

    let rango = {
      desde: 0,
      hasta: CANTIDAD_PAGINADO,
    };

    telecentro.tplay.core.biz.parentalControl.getBlockedChannels(
      function (data) {
        //No se hace nada ya que los canales bloqueados se guardan en el local storage
      },
      function (error) {
        self.$bus.$emit(
          "show-toast",
          "error",
          "No se ha podido cargar los canales bloqueados"
        );
      }
    );

    telecentro.tplay.core.biz.ncf.getBanners(
      function (_banners) {
        self.banners = _banners;

        telecentro.tplay.core.epg.cargarEpg(
          new Date(),
          new Date(),
          function (epg) {
            self.getRails().then(() => {
              // console.log('done rails');
            });
          }
        );

        self.showLoader = false;
      },
      function (_error) {
        self.$bus.$emit("show-toast", "error", _error.message);
      }
    );

    telecentro.tplay.core.events.on(
      "tplay.onCambioProgramacion",
      this.onCambioProgramacion
    );
  },
  watch: {
    tempRails: {
      handler(tRails) {
        const self = this;
        if (tRails.length === self.orderRails.length) {
          // eslint-disable-next-line array-callback-return
          self.orderRails.map((item) => {
            // eslint-disable-next-line array-callback-return
            tRails.map((i) => {
              if (i.id === item) {
                self.rails.push({
                  title: i.title || "",
                  data: i.data,
                });
              }
            });
          });
        }
      },
      immediate: false,
    },
  },

  methods: {
    getRails() {
      const self = this;
      return new Promise((resolve) => {
        telecentro.tplay.core.biz.ncf.getRails(
          (_rails) => {
            if (_rails.rails) {
              // falta el rail de mis grabaciones no viene de core
              __.forEach(_rails.rails, (item) => {
                const railConfig = item;
                // uncomment for local dev
                // railConfig.url = 'assets/top20.json';
                if (
                  item.railId !== "trendLast24hs" &&
                  item.railId !== "trendLive" &&
                  item.railId.toLowerCase() !== "watchagain" &&
                  item.railId.toLowerCase() !== "continuewatching"
                ) {
                  self.tempRails[item.railId] = [];
                  self.orderRails.push(item.railId);
                }
                const { railId, url } = railConfig;
                if (railId === "trendLive") {
                  // Traigo la tendencia actual
                  self.updateTendenciaActual(() => {
                    self.toggleTendencia(
                      self.tendencia.actual.length > 0 ? "actual" : "ultimas24"
                    );
                    self.tendencia.consultada = true;
                  }, url);
                } else if (railId === "trendLast24hs") {
                  // Traigo la tendencia de las últimas 24 horas
                  telecentro.tplay.core.tendencia.ultimas24horas(url, (ret) => {
                    self.tendencia.ultimas24 = ret;

                    self.toggleTendencia(
                      self.tendencia.actual.length > 0 ? "actual" : "ultimas24"
                    );
                    self.tendencia.consultada = true;
                  });
                } else if (railId === "myRecordings") {
                  telecentro.tplay.core.npvr.verGrabaciones((grabaciones) => {
                    const grabacionesActuales =
                      telecentro.tplay.core.seriesEpg.agruparProgramasPorSerieYTemporada(
                        grabaciones.actuales
                      );
                    self.rails.push({
                      title: railConfig.title || railConfig.railId,
                      data: grabacionesActuales,
                    });
                  });
                } else if (railId.toLowerCase() === "continuewatching") {
                  self.continueWatching();
                } else if (railId.toLowerCase() === "watchagain") {
                  self.watchAgain();
                } else if (typeof railConfig.categoryId !== "undefined") {
                  self.processRailPromise(railConfig).then(() => {
                    // console.log('done', railConfig.title);
                  });

                  // telecentro.tplay.core.vod.getListContent(railConfig.categoryId, rango, (category) => {
                  //     self.rails.push({
                  //         title: railConfig.title || '',
                  //         data: category,
                  //     });
                  // });
                }
              });
              self.$bus.$emit("show-skeleton", false);
              this.rails = [];
            }
            resolve();
          },
          (_error) => {
            self.$bus.$emit("show-toast", "error", _error.message);
          }
        );
      });
    },
    processRailPromise(railConfig) {
      const self = this;
      return new Promise((resolve) => {
        telecentro.tplay.core.vod.getListContent(
          railConfig.categoryId,
          rango,
          (category) => {
            // self.rails.push({
            //     title: railConfig.title || '',
            //     data: category,
            // });
            self.tempRails.push({
              id: railConfig.railId,
              title: railConfig.title || "",
              data: category,
            });
            resolve(true);
          }
        );
      });
    },

    toggleTendencia(tendencia) {
      if (
        this.tendencia.seleccionada != tendencia &&
        this.tendencia.seleccionada != ""
      ) {
        tplay.sendGrafanaEvent(
          tplay.grafana.event.BUTTON,
          { name: tendencia, sub_type: "tendencias", screen: this.$route.name },
          tplay.grafana.actions.ENTER
        );
      }

      this.tendencia.seleccionada = tendencia;
    },

    updateTendenciaActual(onFinish, url) {
      const self = this;

      telecentro.tplay.core.tendencia.actual(
        url,
        (programasTendenciaActual) => {
          //self.tendencia.actual = programasTendenciaActual;
          // console.log('update tendencia actual');
          self.$set(self.tendencia, "actual", programasTendenciaActual);
          if (self.tendencia.actual.length > 0) {
            self.toggleTendencia("actual");
          }

          clearTimeout(self.timerTendencia);

          self.timerTendencia = setTimeout(() => {
            self.updateTendenciaActual();
          }, 1000 * 60 * 15); // 15 minutos

          if (typeof onFinish === "function") {
            onFinish();
          }
        }
      );
    },

    onCambioProgramacion(lcn, programa) {
      //console.log('ejecuta onCambioProgramacion', programa,  this.tendencia.actual);

      let programacion = [];
      for (let i = 0; i < this.tendencia.actual.length; i++) {
        // aprovecho el for para controlar los programas que no se han actualizado por tendencia
        // ya que en algunos casos se muestran programas que el front interpreta como FUTUROS
        // y en realidad ya están en vivo

        this.tendencia.actual[i].status = getStatus(this.tendencia.actual[i]);

        if (
          this.tendencia.actual[i] &&
          programa.channel.id === this.tendencia.actual[i].channel.id &&
          programa.idAsset !== this.tendencia.actual[i].idAsset
        ) {
          //Uso Vue.set en vez de una asignación, para mantener la reactividad
          //this.$set(this.tendencia.actual, i, programa);
          //break;
          programacion.push(programa);
        } else {
          programacion.push(this.tendencia.actual[i]);
        }
      }
      this.$set(this.tendencia, "actual", programacion);
    },

    continueWatching() {
      const self = this;
      const optionalParams = {
        allAssets: "1",
        contentTypeFilter: "all",
        watchingMax: 89,
        limit: 15,
        watchingMin: 1,
      };
      telecentro.tplay.core.biz.vod
        .continueWatching(optionalParams)
        .then((r) => {
          self.continueWatchingRail = r;
          //console.log('continueWatchingRails', r);
        });
    },

    watchAgain() {
      const self = this;
      const optionalParams = {
        allAssets: "1",
        contentTypeFilter: "all",
        watchingMin: 90,
        limit: 15,
      };
      telecentro.tplay.core.biz.vod.watchItAgain(optionalParams).then((r) => {
        self.watchAgainRail = r;
        //console.log('watchAgainRail', r);
      });
    },
  },

  beforeDestroy() {
    // Quito las clases que son solamente para la home
    document.body.classList.remove("home");

    clearTimeout(this.timerTendencia);
    telecentro.tplay.core.epg.eventoCambioProgramacion.off(
      this.onCambioProgramacion
    );
  },
};
</script>
